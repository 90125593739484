import util from "./util.js";

/**
 * Manage:
 * * Logo color update ("c" keyboard")
 * * Gradient color angle ("m" keyboard: follow mouse or move periodically)
 */
class Logo {

	// Disable logo color and mouse
	DISABLED = true;

	gradients = {
		"white"  : ["white"],
		"sunset" : ["0%", "#FBDA61", "100%", "#FF5ACD"],
		"green"  : ["0%", "#63FBD7", "100%", "#64FBD7"],
		"bandw"  : ["0%", "#000000", "100%", "#FFFFFF"],
		"rainbow": ["0%", "crimson",  "49%", "gold", "50%" , "lemonchiffon", "51%" , "gold", "100%", "teal",],
	};

	constructor(logo, periodicity = 200, colorMode = "white", motion = "none") {
		this.svg         = logo;
		this.gradient    = this.svg.querySelector("linearGradient");
		this.colorMode   = colorMode;
		this.periodicity = periodicity;
		this.motion      = motion;
		this.step        = 0;

		if (this.DISABLED) { return; }
		this.resize();
		this.buildGradient();
	}

	resize() {
		this.width   = window.innerWidth  || document.documentElement.clientWidth;
		this.height  = window.innerHeight || document.documentElement.clientHeight;
		this.centerX = this.width / 2;
		this.centerY = this.height / 2;
	}

	incStep() {
		this.step = (this.step + 1) % this.periodicity;
		return util.map(this.step, 0, this.periodicity, 0, 360);
	}

	/**
	 * Update logo color
	 * @params x X mouse corrdinate
	 * @params y Y mouse corrdinate
	 */
	draw(a, b) {
		if (this.DISABLED) { return; }

		switch (this.motion) {
			case "mouse":
				this.setAngle(Math.atan2(b - this.centerY, a - this.centerX) * 180 / Math.PI);
				break;
			case "periodic":
				this.setAngle(this.incStep());
				break;
		}
	}

	setAngle(angle) {
		this.gradient && this.gradient.setAttribute("gradientTransform", `rotate(${angle})`);
	}

	switchColorMode() {
		if (this.DISABLED) { return; }

		const colors   = Object.keys(this.gradients).filter(c => c !== this.colorMode);
		this.colorMode = colors[Math.floor(Math.random() * colors.length)];
		this.buildGradient();
	}


	switchMotion() {
		const motions = ["none", "mouse", "periodic"].filter(c => c !== this.colorMode);
		this.motion = motions[Math.floor(Math.random() * motions.length)];
	}

	/**
	 * Dynamically create a linear gradient to apply to the SVG
	 *
	 * <svg fill="url(#sunset)">
	 *    ...
	 *    <defs>
	 *       <linearGradient id="sunset" gradientTransform="rotate(-60)" transform-origin="50% 50%" gradientUnits="userSpaceOnUse">
	 *          <stop offset="0%"   stop-color="#FBDA61"/>
	 *          <stop offset="100%" stop-color="#FF5ACD"/>
	 *       </linearGradient>
	 *    </defs>
	 * </svg>
	 * @param {Number} angle Gradient angle
	 */
	buildGradient(angle = -60) {
		// Clean up
		const oldDef = this.svg.querySelector("defs");
		oldDef && oldDef.remove();

		// Use fill attribute directly if we have to deal with only one color
		const colors = this.gradients[this.colorMode];
		if (colors.length <= 1) {
			this.svg.setAttribute("fill", colors);
			this.gradient = null;
			return;
		}

		// Create gradient
		const svgns     = "http://www.w3.org/2000/svg";
		const dGradient = document.createElementNS(svgns, "linearGradient");
		dGradient.setAttribute("id", this.colorMode);
		dGradient.setAttribute("gradientTransform", `rotate(${angle})`);
		dGradient.setAttribute("transform-origin", "50% 50%");
		dGradient.setAttribute("gradientUnits", "userSpaceOnUse");

		for (let i = 0; i < colors.length; i += 2) {
			const dStop = document.createElementNS(svgns, "stop");
			dStop.setAttribute("offset"    , colors[i + 0]);
			dStop.setAttribute("stop-color", colors[i + 1]);
			dGradient.appendChild(dStop);
		}

		const defs = document.createElementNS(svgns, "defs");
		defs.appendChild(dGradient);
		this.svg.appendChild(defs);
		this.svg.setAttribute("fill", `url(#${this.colorMode})`);
		this.gradient = dGradient;
	}
}

export default function (canvas, periodicity, colorMode, motion) {
	return new Logo(canvas, periodicity, colorMode, motion);
}
