import util from "./util.js";

class Spirographe {

	constructor(canvas, periodicity = 200, colorMode = "gray") {
		this.cvs       = canvas;
		this.ctx       = canvas.getContext("2d");
		this.colorMode = colorMode;
		this.waveMode  = false;
		this.resize(true);
		this.resetStep(periodicity);
		this.freeze    = false;
	}

	resize(clear = false) {
		this.width      = window.innerWidth  || document.documentElement.clientWidth;
		this.height     = window.innerHeight || document.documentElement.clientHeight;
		this.centerX    = this.width / 2;
		this.centerY    = this.height / 2;
		this.cvs.width  = this.width;
		this.cvs.height = this.height;
		clear && this.clear();
	}

	clear() {
		this.ctx.clearRect(0, 0, this.width, this.height);
	}

	toggleFreeze() {
		this.clear();
		this.drawRandom();
		this.freeze = !this.freeze;
	}

	drawSnapshot() {
		const _draw = (a, b, colorMode = null) => {
			this.colorMode = colorMode || this.colorMode;
			this.draw(a, b, 800, 1);
		};

		//_draw(800, 300, "demo"); _draw(300, 800, "demo");
		//_draw(500, 700, "demo");
		//_draw(900, 300, "demo");
		//_draw(1000, 500, "demo"); _draw(140, 140, "lab-light");
		//_draw(800, 300, "lab-light");
		//_draw( 900, 500, "lab-light");
		//_draw(900, 300, "lab-light");
		//_draw(1000, 500, "demo"); _draw(100, 200, "lab-light");
		//_draw(100, 200, "demo"); _draw(1000, 500, "lab-light");
		//_draw(100, 200, "demo");
		_draw(1000, 500, "demo");
	}

	drawRandom(numbers = 1) {
		for (let i = 0; i < numbers; ++i) {
			const a = util.getRandomInt(this.width);
			const b = util.getRandomInt(this.height);
			const h = 800; // util.getRandomInt(50);
			this.draw(a, b, h, 1);
		}
	}

	drawRandomTimer(numbers = 50, timer = 50) {
		let t = 0;
		const handle = setInterval(() => {
			this.drawRandom();
			if (++t >= numbers) {
				clearInterval(handle);
				this.resetStep();
			}
		}, timer);
	}

	timeToGray(periodicity = 20) {
		const currTime = (new Date().getTime()) / periodicity;
		const angle = currTime * (Math.PI / 180);
		return util.map(Math.sin(angle), -1, 1, 0, 90);
	}

	// Set "step" to begin to draw with a black pen.
	// => sin(angle) = -1, angle equals 270 (3 * Math.PI / 2)
	// https://en.wikipedia.org/wiki/Sine#Properties_relating_to_the_quadrants
	resetStep(periodicity = null) {
		this.periodicity = periodicity || this.periodicity;
		this.step = util.map(270, 0, 360, 0, this.periodicity);
	}

	stepToGray() {
		// this.step = (this.periodicity / 2 + (this.step + 1)) % this.periodicity; // epileptic effect XD
		this.step = (this.step + 1) % this.periodicity;
		const angle = util.map(this.step, 0, this.periodicity, 0, 360) * (Math.PI / 180);
		return parseInt(util.map(Math.sin(angle), -1, 1, 0, 90));
	}

	/**
	 * Draw a hypotrochoid
	 * @params a outer circle radius
	 * @params b inner circle radius
	 * @params h coordinate of the point to trace (from the center)
	 * @params s scaling
	 */
	draw(a = 210, b = 24, h = 40, s = 1) {
		if (this.freeze) { return; }
		let oX, oY, x, y, t, oT, d, oD;
		let max    = 361;
		const ctx  = this.ctx;
		const gray = this.stepToGray(); // this.timeToGray();
		x = y = null;

		for (let i = 1; i < max; ++i) {
			t  = util.radians(i);
			oT = util.radians(i - 1);
			d  = a * t;
			oD = a * oT;

			oX = x === null ? (a - b) * Math.cos(oT) + h * Math.cos(oD) : x;
			oY = y === null ? (a - b) * Math.sin(oT) + h * Math.sin(oD) : y;
			x  = s * ((a - b) * Math.cos(t) + h * Math.cos(d));
			y  = s * ((a - b) * Math.sin(t) + h * Math.sin(d));

			if (this.waveMode) {
				oY = oX;
			}

			ctx.beginPath();
			ctx.moveTo(this.centerX + oX, this.centerY + oY);
			ctx.lineTo(this.centerX + x, this.centerY + y);

			// OR: https://stackoverflow.com/questions/22223950/angle-gradient-in-canvas
			switch (this.colorMode) {
				case "demo":
					ctx.strokeStyle = `hsl(0, 0%, 20%)`;
					ctx.globalAlpha = .5;
					break;
				case "gray":
					ctx.strokeStyle = `hsl(0, 0%, ${gray - util.getRandomInt(10)}%)`;
					ctx.globalAlpha = .5;
					break;
				case "multi":
					ctx.strokeStyle = `hsl(${i - 1}, 100%, 50%)`;
					break;
				case "lab-dark":
				case "lab-light":
					const lValue    = this.colorMode === "lab-dark" ? 0 : 90;
					const rgbValue  = util.labToRgb(lValue, util.map(Math.cos(t), -1, 1, -100, 100), util.map(Math.sin(t), -1, 1, -100, 100));
					ctx.strokeStyle = `rgb(${rgbValue[0]}, ${rgbValue[1]}, ${rgbValue[2]})`;
					break;
			}

			ctx.lineWidth = 1;
			ctx.closePath();
			ctx.stroke();
		}

	}

	debug() {
		const ctx = this.ctx;
		ctx.beginPath();
		ctx.moveTo(20, 20);
		ctx.lineTo(120, 120);
		ctx.strokeStyle = "#FF0000";
		ctx.stroke();
		ctx.closePath();
	}

	switchColorMode() {
		let colors = ["gray", "multi", "lab-dark", "lab-light"];
		colors = colors.filter(c => c !== this.colorMode);
		this.colorMode = colors[Math.floor(Math.random() * colors.length)];
	}

}

export default function (canvas, periodicity, colorMode) {
	return new Spirographe(canvas, periodicity, colorMode);
}
