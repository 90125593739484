import getSpirograph from "./spirograph.js";
import getLogo       from "./logo.js";
import util          from "./util.js";

// We could also inline CSS inside index.html (Parcel will prevent creating an additional CSS file)
// Unfortunately, Parcel doesn't handle HMR very well in this case
// <style>@import "./style.styl";</style>
import                    "./style.styl";

const canvasManager = {

	spirograph: null,
	hParam: 900,
	currentTime: new Date(),
	logo: null,

	init({ spiroColorMode = "gray", logoColorMode = "white", logoMotion = "none" } = { }) {
		const canvas = document.querySelector(".rn__canvas");
		this.spirograph = getSpirograph(canvas, 200, spiroColorMode);
		this.spirograph.resetStep();
		// this.spirograph.drawSnapshot();

		this.spirograph.drawRandom(util.getRandomIntBetween(30, 50)); // drawRandom(50);
		//this.spirograph.drawRandomTimer();
		this.spirograph.resetStep();
		this.setHParam();
		setTimeout(() => canvas.classList.add("rn__canvas--show"), 0);

		const svg = document.querySelector(".rn-svg");
		this.logo = getLogo(svg, 200, logoColorMode, logoMotion);
	},

	draw() {
		this.spirograph.draw();
	},

	setHParam() {
		const width = window.innerWidth  || document.documentElement.clientWidth;
		this.hParam = util.getRandomIntBetween(width * 1 / 3, width / 2);
	},

	trackResize() {
		this.setHParam();
		this.spirograph.resize(false);
		this.spirograph.drawRandom();
		this.logo.resize();
	},

	trackMouse(event) {
		const e = event || window.event; // IE-ism
		const a = e.clientX || e.pageX;
		const b = e.clientY || e.pageY;
		const h = this.hParam;
		this.spirograph.draw(a, b, h);
		this.logo.draw(a, b);

		const time = new Date();
		if (time - this.currentTime > 60000) {
			this.currentTime = time;
			this.spirograph.switchColorMode();
		}
	},

	trackPoint(event) {
		this.trackMouse({
			clientX: event.touches[0].clientX,
			clientY: event.touches[0].clientY,
		});
	},

	/**
	 * Track keyboard events
	 * - Backspace  Clear canvas
	 * - x          Switch color mode
	 * - m          Switch mouse track motion (logo only)
	 * - f          Freeze screen
	 * - w          Switch wave mode mode
	 * @param {*} event
	 * @returns
	 */
	trackKeyboard(event) {
		if (event.defaultPrevented) { return; }

		switch (event.key) {
			case "Backspace":
			case "Escape":
			case "Delete":
			case "Esc":
			case "Del":
				this.spirograph.clear();
				break;
			case "c":
			case "C":
				this.spirograph.switchColorMode();
				this.logo.switchColorMode(); // Logo color mode update is disabled for now
				break;
			case "m":
			case "M":
				this.logo.switchMotion(); // Logo color update is disabled for now
				break;
			case "f":
			case "F":
				this.spirograph.toggleFreeze();
				break;
			case "w":
			case "W":
				this.spirograph.waveMode = !this.spirograph.waveMode;
				break;
		}
	}

};

const events = {

	events: { },

	addEvents() {
		const trackMouse = util.throttle(30, canvasManager.trackMouse, canvasManager);
		const trackPoint = util.throttle(30, canvasManager.trackPoint, canvasManager);

		window.onresize    = (event) => canvasManager.trackResize(event);
		window.onkeydown   = (event) => canvasManager.trackKeyboard(event);
		window.onmousemove = trackMouse;

		// Remove rubberband scrolling from web apps on mobile safari (iOS)
		// https://gist.github.com/amolk/1599412#gistcomment-2129095
		this.events.touchmove = (event) => { event.preventDefault(); trackPoint(event); };
		document.addEventListener("touchmove", this.events.touchmove, { passive: false, useCapture: false });
	},

	remEvents() {
		document.removeEventListener("touchmove", this.events.touchmove, { passive: false, useCapture: false });
	}

}

document.addEventListener("DOMContentLoaded", () => {
	canvasManager.init();
	events.addEvents();
});

if (module.hot) {
	module.hot.dispose(function (data) {
		data.spiroColorMode = canvasManager.spirograph.colorMode;
		data.logoColorMode  = canvasManager.logo.colorMode;
		data.logoMotion     = canvasManager.logo.motion;
		events.remEvents();
	});

	module.hot.accept(function () {
		canvasManager.init(module.hot.data);
		events.addEvents();
	});
}
