import lab from "color-space/lab";
import xyz from "color-space/xyz";

export default {
	// Converts from degrees to radians.
	radians(degrees) {
		return degrees * Math.PI / 180;
	},

	map(n, start1, stop1, start2, stop2) {
		return (n - start1) / (stop1 - start1) * (stop2 - start2) + start2;
	},

	// 3 => 0, 1 or 2
	getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	},

	// 1, 3 => 1 or 2
	getRandomIntBetween(min, max) {
		return Math.floor(Math.random() * (max - min) + min);
	},

	// https://github.com/colorjs/color-space
	labToRgb(l, a, b) {
		const xyzValue = lab.xyz([l, a, b]);
		const rgbValue = xyz.rgb(xyzValue);
		return [parseInt(rgbValue[0]), parseInt(rgbValue[1]), parseInt(rgbValue[2])];
	},

	// Throttle function
	// https://github.com/m-gagne/limit.js
	throttle(milliseconds, fct, context) {
		let baseFunction = fct, lastEventTimestamp = null, limit = milliseconds;

		return (...args) => {
			let self = context || this, now = Date.now();
			if (!lastEventTimestamp || now - lastEventTimestamp >= limit) {
				lastEventTimestamp = now;
				baseFunction.apply(self, args);
			}
		};
	}

};
